<template>
  <div class="buer-container">
    COMPANY PROFILE
    <br />
    <br />
    <el-row type="flex" justify="center">
      <div class="border-buttom-red">
        <img :src="logoblack" alt="" style="width: 110px; height: 80px" />
      </div>
    </el-row>
    <br />
    <div style="background-color: #f4f4f4; padding: 30px">
      <div style="padding-bottom:10px;line-height:38px" class="buer-large buer-bold">立林(宿迁)生物科技有限公司</div>
      <div>
        立林(宿迁)生物科技有限公司，简称立林生物，位于国家级经济开发区宿迁市经开区产业园内。
        <br />
        是一家专注于宠物营养保健品加工生产、原料研制、配方开发及配套性服务为一体的OEM/ODM生产及服务企业。
      </div>
    </div>
  </div>
</template>

<script>
import logoblack from "../assets/img/logoblack.png";
export default {
  data() {
    return {
      logoblack,
    };
  },
};
</script>

<style scoped>
.border-buttom-red {
  border-bottom: red solid 1px;
}
</style>