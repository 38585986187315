<template>
  <div style="width:100%">
    <el-carousel height="728px" width="1592px" arrow="never">
      <el-carousel-item v-for="item in carouselImgs" :key="item" style="width:100%">
        <el-image :src="item" :fit="fill" style=";width:1592px;height:728px"></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import firstImg from "../assets/img/carousel/1.jpg";
import secondImg from "../assets/img/carousel/2.jpg";
import thirdImg from "../assets/img/carousel/3.jpg";

export default {
  data() {
    return {
      carouselImgs: [firstImg, secondImg, thirdImg],
    };
  },
};
</script>

<style>
/* buer start */
.el-carousel {
  height: unset;
}
.el-carousel__indicators--horizontal {
  left: 71.8%;
}

.el-carousel__indicator.is-active > .el-carousel__button {
  background-color: red;
}
.el-carousel__indicator {
  width: 102px;
}
.el-carousel__button {
  width: 100px;
}
</style>