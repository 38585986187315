<template>
  <div class="el-menu-demo">
    <el-menu
    class="el-menu-demo"
      :default-active="activeIndex2"
      mode="horizontal"
      @select="handleSelect"
      background-color="#323232"
      text-color="#fff"
      active-text-color="#fff"
    >
      <el-row>
        <el-col :span="10" :offset="2">
          <el-menu-item class="logo-place" @click="toTop">
            <img :src="logo" alt="" style="height: 93%" />
          </el-menu-item>
        </el-col>
        <el-col :span="3">
          <el-menu-item index="1" @click="toAboutPosition"
            >关于立林</el-menu-item
          >
        </el-col>
        <el-col :span="3">
          <el-menu-item index="2" @click="toServicePosition"
            >代工服务</el-menu-item
          >
        </el-col>
        <el-col :span="3">
          <el-menu-item index="3" @click="toContactUsPosition"
            >联系我们</el-menu-item
          >
        </el-col>
      </el-row>
    </el-menu>
    <div class="menu-underline"></div>
  </div>
</template>

<script>
import store from "../store.js";
import logo from "../assets/img/logomenu.png";

export default {
  data() {
    return {
      logo: logo,
      activeIndex: "1",
      activeIndex2: "1",
    };
  },
  methods: {
    handleSelect(/*key, keyPath*/) {
      // console.log(key, keyPath);
      // 更新各position
      // console.log("触发事件")
      this.$emit("update-position");
    },

    toAboutPosition: function () {
      window.scrollTo({
        //滚动到元素位置
        //top: this.$refs[pos][0].offsetTop,//offsetTop 相对于offsetparent 定位,当有定位时，不准确。
        top: store.state.aboutPosition + window.scrollY, //推荐使用。getBoundingClientRect 相对于当前视口的位置
        //top: 400//滚动到指定距离
        //top: 0,//滚动到顶部
        //top: document.documentElement.scrollHeight,//滚动到底部
        behavior: "smooth", // 平滑滚动
      });
    },
    toTop: function () {
      window.scrollTo({
        top: 0,//滚动到顶部
        behavior: "smooth", // 平滑滚动
      });
    },
    toServicePosition: function () {
      window.scrollTo({
        top: store.state.servicePosition + window.scrollY,
        behavior: "smooth", // 平滑滚动
      });
    },
    toContactUsPosition: function () {
      window.scrollTo({
        top: store.state.contactPosition + window.scrollY,
        behavior: "smooth", // 平滑滚动
      });
    },
  },
};
</script>

<style scoped>
.el-menu-demo{
  width: 100%;
}

/* 去除点击之后下面有一个标识横线 */
.el-menu-item.is-active {
  border-bottom: 0px;
}
.el-menu-item {
  padding-left: 5%;
  padding-right: 5%;
}
.menu-underline {
  width: 100%;
  height: 2px;
  background-color: red;
  position: relative;
  top: -2px;
}
.logo-place {
  width: 50%;
}
</style>