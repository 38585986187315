<template>
  <div
    style="
      background-color: #f4f4f4;
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
      margin-top: 65px;
    "
  >
    <div style="padding: 30px">
      CONTACT US
      <div style="display: flex; justify-content: center">
        <div class="border-buttom-red buer-bold">联系我们</div>
      </div>
    </div>

    <div style="display: flex; justify-content: center">
      <div
        style="
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
        "
      >
        <div class="desc-txt">
          <div>
            <div style="color: red" class="buer-larger">公司地址</div>
            江苏省宿迁市经济技术开发区智能小家电产业园东区A1栋标准厂房
            <el-divider></el-divider>
            <br />
            联系人: 张经理
            <br />
            联系电话: 13590277525
            <br />
            邮箱: liujunjie@caregiverpet.com
            <el-divider></el-divider>
          </div>
        </div>
        <buer-map />
      </div>
    </div>
  </div>
</template>

<script>
import BuerMap from "./tools/BuerMap.vue";
export default {
  components: {
    BuerMap,
  },
};
</script>

<style scoped>
.desc-txt {
  text-align: left;
  margin-left: 88px;
  margin-right: 88px;
  height: 100%;
  display: flex;
  align-items: center;
}
.border-buttom-red {
  border-bottom: red solid 1px;
  padding: 8px;
}
</style>