<template>
  <div>
    <div style="margin: 30px" class="buer-bold">产品中心</div>

    <div style="display: flex; justify-content: center">
      <div style="width: 78%">
        <div
          style="display: flex; justify-content: center; flex-wrap: wrap"
        >
          <div v-for="item in imgs" :key="item" style="border:solid 4px white">
            <el-card
              :body-style="{ padding: '0px' }"
              style="margin-bottom: 13px"
            >
              <img :src="item.src" class="image" />
              <div style="padding: 14px">
                <span>{{ item.desc }}</span>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import one from "../assets/img/products/0001.jpg";
import two from "../assets/img/products/0002.jpg";
import three from "../assets/img/products/0003.jpg";
import four from "../assets/img/products/0004.jpg";
import five from "../assets/img/products/0005.jpg";
import six from "../assets/img/products/0006.jpg";

export default {
  data() {
    return {
      imgs: [
        {
          src: one,
          desc: "羊奶粉",
        },
        {
          src: two,
          desc: "片剂系列",
        },
        {
          src: three,
          desc: "粉剂系列",
        },
        {
          src: four,
          desc: "膏剂系列",
        },
        {
          src: five,
          desc: "营养液等口服制剂系列",
        },
        {
          src: six,
          desc: "卵磷脂等软锭系列",
        },
      ],
    };
  },
};
</script>