<template>
  <div>
    <div class="buer-bold">厂容厂貌</div>
  
    <br />
    <br />

    <div style="width: 100%; display: flex; justify-content: center">
      <div style="display: flex; justify-content: space-between">
        <div style="border: solid #f4f4f4 3px">
          <el-image :src="one" :fit="fill"></el-image>
        </div>
        <div style="border: solid #f4f4f4 3px">
          <el-image :src="two" :fit="fill"></el-image>
        </div>
        <div style="border: solid #f4f4f4 3px">
          <el-image :src="three" :fit="fill"></el-image>
        </div>
        <div style="border: solid #f4f4f4 3px">
          <el-image :src="four" :fit="fill"></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import one from "../assets/img/factory/1.jpg";
import two from "../assets/img/factory/2.jpg";
import three from "../assets/img/factory/3.jpg";
import four from "../assets/img/factory/4.jpg";

export default {
  data() {
    return {
      one,
      two,
      three,
      four,
    };
  },
};
</script>

<style scoped>
</style>