<template>
  <div
    @mouseenter="isenter = true"
    @mouseleave="isenter = false"
    :style="
      'width:' +
      imgData.width +
      ';' +
      'height:' +
      imgData.height +
      ';border:solid #f4f4f4 3px'
    "
  >
    <div style="height: 100%; width: 100%; background-color: #f4f4f4">
      <img
        :src="imgData.src"
        style="height: 100%; width: 100%; object-fit: fill"
      />
    </div>
    <!-- <transition name="el-zoom-in-bottom"> -->
    <div class="zhezhao" v-show="isenter">
      <div class="zhezhao_desc">
        {{ imgData.desc }}
      </div>
    </div>
    <!-- </transition> -->
  </div>
</template>
<script>
// https://cn.vuejs.org/v2/api/#transition

export default {
  props: ["imgData"],
  data() {
    return {
      isenter: false,
    };
  },
};
</script>

<style scoped>
.zhezhao {
  width: 100%;
  height: 100%;
  position: relative;
  top: -100%;
  background-color: rgb(37, 37, 37);
  opacity: 0.75;
}

.zhezhao_desc {
  color: white;
  font-size: large;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 5px;
}
</style>