<template>
  <div id="app">
    <el-row id="buer-menu">
      <el-col span="24">
        <buer-menu v-on:update-position="onUpdatePosition" />
      </el-col>
    </el-row>
    <!-- <div id="replace-menu"></div> -->
    <buer-carousel style="width: 100%" />

    <company-profile class="top-empty" ref="part1" />
    <lilin-factory class="top-empty" />
    <research-production class="top-empty" />
    <service-area class="top-empty" ref="part2" />
    <product-center class="top-empty" />

    <contact-us ref="part3" />

    <br />

    <div style="width:300px;margin:0 auto; padding:20px 0;">
     <a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img :src="jc" style="float:left;"/>
     <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">苏公网安备 32130202080986号</p></a>
    </div>
    <!-- <el-link
      href="https://beian.miit.gov.cn"
      target="_blank"
      style="
        height: 50px;
        font-size: large;
        letter-spacing: 2px;
        padding: 50px;
        font-family: 'Microsoft YaHei';
      "
    >
      网站名称：立林（宿迁）生物科技有限公司 | 网站备案号：苏ICP备2021019899号
    </el-link> -->

  </div>
</template>

<script>
import store from "./store.js";
import BuerMenu from "./components/BuerMenu.vue";
import BuerCarousel from "./components/BuerCarousel.vue";

import CompanyProfile from "./components/CompanyProfile.vue";
import LilinFactory from "./components/LilinFactory.vue";
import ResearchProduction from "./components/RearshProduction.vue";
import ServiceArea from "./components/ServiceArea.vue";
import ProductCenter from "./components/ProductCenter.vue";

import ContactUs from "./components/ContactUs.vue";
import jc from "./assets/jc.png";

export default {
  name: "App",
  data() {
    return {jc};
  },
  components: {
    BuerMenu,
    BuerCarousel,
    CompanyProfile,
    LilinFactory,
    ResearchProduction,
    ServiceArea,
    ProductCenter,
    ContactUs,
  },
  methods: {
    getPosition: function () {
      store.setAboutPositionAction(
        this.$refs.part1.$el.getBoundingClientRect().top
      );
      store.setServicePositionAction(
        this.$refs.part2.$el.getBoundingClientRect().top
      );
      store.setContactPositionAction(
        this.$refs.part3.$el.getBoundingClientRect().top
      );
    },

    // 监听menu的事件，更新position具体位置
    onUpdatePosition: function () {
      // console.log("接收到事件")
      this.getPosition();
    },
  },
  mounted() {
    this.getPosition();
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: "微软雅黑";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}

#buer-menu {
  position: fixed;
  width: 100%;
  z-index: 999;
}

/* #replace-menu {
  height: 70px;
} */

#main-container {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

.top-empty {
  padding-top: 88px;
}

.buer-container {
  padding-left: 12%;
  padding-right: 12%;
}

.buer-large {
  font-size: large;
}

.buer-larger {
  font-size: larger;
}

.buer-xlarge {
  font-size: x-large;
}
.buer-bold {
  font-weight: bold;
}
.buer-bolder {
  font-weight: bolder;
}
</style>
