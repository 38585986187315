var store = {
    debug: true,
    state: {
      aboutPosition:0,
      servicePosition:0,
      contactPosition:0,
    },
    setAboutPositionAction (newValue) {
      if (this.debug) console.log('setAboutPositionAction triggered with', newValue)
      this.state.aboutPosition = newValue
    },
    clearAboutPositionAction () {
      if (this.debug) console.log('clearAboutPositionAction triggered')
      this.state.aboutPosition = ''
    },
    setServicePositionAction (newValue) {
      if (this.debug) console.log('setServicePositionAction triggered with', newValue)
      this.state.servicePosition = newValue
    },
    clearServicePositionAction () {
      if (this.debug) console.log('clearServicePositionAction triggered')
      this.state.servicePosition = ''
    },
    setContactPositionAction (newValue) {
      if (this.debug) console.log('setContactPositionAction triggered with', newValue)
      this.state.contactPosition = newValue
    },
    clearContactPositionAction () {
      if (this.debug) console.log('clearContactPositionAction triggered')
      this.state.contactPosition = ''
    }
  }

  export default store