<template>
  <div>
    RESEARCH AND DEVELOPMENT
    <el-row type="flex" justify="center">
      <div class="border-buttom-red buer-bold">研发生产</div>
    </el-row>
    <br />
    <br />

    <div style="width: 100%; display: flex; justify-content: center">
      <div style="display: flex; justify-content: space-between">
        <div class="one-research-production">
          <div class="line1-size line1-red-bg">
            <div class="line1-txt">
              公司拥有超10000㎡标准厂房，设有固体、液体、半固体宠物保健品产线。
            </div>
          </div>
          <img :src="one" alt="" class="line2-size" />
        </div>
        
        <div class="one-research-production">
          <img :src="two" alt="" class="line1-size" />
          <div class="line2-size line2-gray-bg">
            <div class="line2-txt">
              公司拥有十年经验的专业研发团队，可承接膏剂、粉剂、片剂、液体等多类产品的代加工业务，品种齐全。
            </div>
          </div>
        </div>
        
        <div class="one-research-production">
          <div class="line1-size line1-red-bg">
            <div class="line1-txt">
              严格执行饲料质量安全管理规范，并建有完善的生产、检验等过程管理控制。
            </div>
          </div>
          <img :src="three" alt="" class="line2-size" />
        </div>
        
        <div class="one-research-production">
          <img :src="four" alt="" class="line1-size" />
          <div class="line2-size line2-gray-bg">
            <div class="line2-txt">
              立林生物坚持以产品质量为企业核心竞争力，将不断进行产品创新和服务改进，全力跟随客户需求。
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import one from "../assets/img/research/1.jpg";
import two from "../assets/img/research/2.jpg";
import three from "../assets/img/research/3.jpg";
import four from "../assets/img/research/4.jpg";

export default {
  data() {
    return {
      one,
      two,
      three,
      four,
    };
  },
};
</script>

<style scoped>
.border-buttom-red {
  border-bottom: red solid 1px;
  padding: 8px;
}


/* 图片样式调整开始 */
.one-research-production {
  height: 542px;
  width: 187px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-left: solid white 5px;
  border-right: solid white 5px;
}

.line1-size {
  height: 287px;
  width: 187px;
}

.line2-size {
  height: 255px;
  width: 187px;
}

.line1-red-bg {
  background-color: #c2272b;
  color: white;
}

.line2-gray-bg {
  background-color: #a9a9a9;
  color: white;
}

.line1-txt {
  padding: 50px 10px;
  text-align: left;
  line-height: 28px;
}

.line2-txt {
  padding: 30px 10px;
  text-align: left;
  line-height: 28px;
}


</style>