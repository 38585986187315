<template>
  <div>
    SERVICE AREA
    <div style="display: flex; justify-content: center">
      <div class="border-buttom-red buer-bold">服务介绍</div>
    </div>
    <br />

    <!-- 图片部分 -->
    <div style="width: 100%; display: flex; justify-content: center">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <!-- 第一行图片 -->
        <div style="display: flex; justify-content: space-between">
          <div>
            <buer-img :imgData="one_" />
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 334px;
            "
          >
            <buer-img :imgData="two_" />
            <buer-img :imgData="three_" />
          </div>
          <div>
            <buer-img :imgData="four_" />
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 334px;
            "
          >
            <buer-img :imgData="five_" />
            <buer-img :imgData="six_" />
          </div>
        </div>

        <!-- 空点儿 -->
        <div style="height: 1px"></div>

        <!-- 第二行图片 -->
        <div style="display: flex; justify-content: space-between">
          <buer-img :imgData="seven_" />
          <buer-img :imgData="eight_" />
        </div>
      </div>
    </div>

    <div style="margin-top: 30px; margin-bottom: 30px;font-size:x-large">
      一站式服务体系，为客户成就品牌梦
    </div>

    <div style="width: 100%; display: flex; justify-content: center">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: #f4f4f4;
          padding:10px
        "
      >

        <div style="display: flex; justify-content: space-between">
          <div style="margin-left: 50px">
            <img :src="dogCat" alt="" />
          </div>
          <div style="margin-right:50px;margin-left:50px">
            <br>
            <div class="desc-txt buer-larger">
              提供OEM/ODM代工产品类型如下：
            </div>
            <br>
            <div class="desc-txt">
              1.保健功能膏剂系列，各种营养性补充剂（营养膏、化毛膏、整肠膏等）
            </div>
            <br />
            <div class="desc-txt">
              2.保健功能液体口服制剂系列（营养液、氨基酸口服液、美毛口服液及各种功能性口服液）
            </div>
            <br />
            <div class="desc-txt">3.保健功能片剂（钙片、维生素片，氨基酸）</div>
            <br />
            <div class="desc-txt">
              4.保健功能粉剂（益生菌粉、犬猫羊奶粉、蛋白美毛粉）
            </div>
            <br />
            <div class="desc-txt">5.保健功能颗粒剂系列（卵磷脂颗粒）</div>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BuerImg from "./tools/BuerImg.vue";

import one from "../assets/img/serviceInduction/1.jpg";
import two from "../assets/img/serviceInduction/2.jpg";
import three from "../assets/img/serviceInduction/3.jpg";
import four from "../assets/img/serviceInduction/4.jpg";
import five from "../assets/img/serviceInduction/5.jpg";
import six from "../assets/img/serviceInduction/6.jpg";
import seven from "../assets/img/serviceInduction/7.jpg";
import eight from "../assets/img/serviceInduction/8.jpg";

import dogCat from "../assets/img/serviceInduction/9.jpg";

export default {
  data() {
    return {
      one_: { src: one, desc: "贴牌加工", width: "291px", height: "334px" },
      two_: { src: two, desc: "调配配方", width: "297px", height: "163px" },
      three_: { src: three, desc: "免费寄样", width: "297px", height: "163px" },
      four_: { src: four, desc: "质检报告", width: "292px", height: "334px" },
      five_: { src: five, desc: "品牌策划", width: "297px", height: "163px" },
      six_: { src: six, desc: "包装设计", width: "297px", height: "163px" },
      seven_: { src: seven, desc: "包材采购", width: "593px", height: "197px" },
      eight_: { src: eight, desc: "免费备案", width: "593px", height: "197px" },

      dogCat,
    };
  },
  components: {
    BuerImg,
  },
};
</script>

<style scoped>
.border-buttom-red {
  border-bottom: red solid 1px;
  padding: 8px;
}
.desc-txt {
  text-align: left;
}
</style>