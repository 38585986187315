<template>
  <div>
    <baidu-map
      class="bm-view"
      :ak="ak"
      :center="position"
      :zoom="11"
      :scroll-wheel-zoom="true"
    >
      <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
      <bm-marker
        :position="position"
        :dragging="true"
        animation="BMAP_ANIMATION_BOUNCE"
      ></bm-marker>
    </baidu-map>
  </div>
</template>

<script>
//  文档：
//  https://dafrok.github.io/vue-baidu-map/#/zh/start/usage

import { BaiduMap, BmScale, BmNavigation, BmMarker } from "vue-baidu-map";
export default {
  data() {
    return {
      ak: "W1dsqGBqfnmD1322hSj8tyXVgAsYSGCS",
      // position:"江苏省宿迁市经济技术开发区智能小家电产业园东区A1栋标准厂房"   // 用"百度经纬度获取工具 - 拾取坐标系统"获取经纬度
      position: { lng: 118.252, lat: 33.911 },
    };
  },
  components: {
    BaiduMap,
    BmScale,
    BmNavigation,
    BmMarker,
  },
};
</script>

<style scoped>
.bm-view {
  width: 350px;
  height: 350px;
}
</style>